import { Alert, Stack } from "@mui/material";
import { theme } from "@polifonia/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { MotifIcon } from "@/components/MotifIcon";

interface WccsActivationProps {
  webChatModuleEnabled?: boolean | undefined;
  webTrackingEnabled?: boolean | undefined;
}

export const WccsActivation: FC<WccsActivationProps> = ({
  webChatModuleEnabled = false,
  webTrackingEnabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="4px">
      {webTrackingEnabled ? (
        <Alert
          severity="success"
          variant="outlined"
          icon={
            <MotifIcon
              un-i-motif="circle_check"
              style={{
                fontSize: 16,
                color: theme.colors.staticFgPositive,
              }}
            />
          }
        >
          {t("channels.wccs.activation.webTracking")}
        </Alert>
      ) : null}
      {webChatModuleEnabled ? (
        <Alert
          severity="success"
          variant="outlined"
          icon={
            <MotifIcon
              un-i-motif="circle_check"
              style={{
                fontSize: 16,
                color: theme.colors.staticFgPositive,
              }}
            />
          }
        >
          {t("channels.wccs.activation.webChatModule")}
        </Alert>
      ) : null}
    </Stack>
  );
};
