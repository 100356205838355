import { memo } from "@chatbotgang/etude/react/memo";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme as useMuiTheme } from "@mui/material/styles";
import { theme } from "@polifonia/theme";
import { stringToColor } from "@polifonia/theme/colors";
import {
  getRouteApi,
  Outlet,
  useMatches,
  useNavigate,
} from "@tanstack/react-router";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA4 from "react-ga4";
import { useTranslation } from "react-i18next";

import { BarLoading } from "@/components/Loading/BarLoading";
import { Tooltip } from "@/components/Tooltip";
import { useLoginMutation } from "@/features/auth/useLoginMutation";
import { useTokenStore } from "@/features/auth/useTokenStore";
import { useFeatureFlag } from "@/features/featureFlag";
import { interlude } from "@/interlude";
import { ErrorFallbackPage } from "@/layout/ErrorBoundary/ErrorFallbackPage/index";
import { menuItems } from "@/pages/routes";
import CL_LOGO from "@/statics/CresclabIcon.png";

const ROUTES = ["applications", "billing", "channels"] as const;

const DRAWER_WIDTH = 268;

const DrawerHeader = styled("div")(({ theme: muiTheme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: muiTheme.spacing(3, 2),
  color: theme.colors.neutral100,
  fontSize: "1.25rem",
  fontWeight: 500,
  boxShadow: "0px 2px 4px 0px rgba(34, 59, 83, 0.05)",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme: muiTheme, open }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.colors.neutral010,
  transition: muiTheme.transitions.create("margin", {
    easing: muiTheme.transitions.easing.sharp,
    duration: muiTheme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: muiTheme.transitions.create("margin", {
      easing: muiTheme.transitions.easing.easeOut,
      duration: muiTheme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const routeApi = getRouteApi("__root__");

export const MainContent = memo(function MainContent() {
  const { t } = useTranslation();
  const muiTheme = useMuiTheme();
  const navigate = useNavigate();
  const matches = useMatches();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const routeSearch = routeApi.useSearch();
  const loginMutation = useLoginMutation();

  const meInfoQuery = interlude.auth.useGetMe(undefined, {
    enabled:
      !routeSearch.token &&
      (loginMutation.isSuccess || !!useTokenStore.getState().value),
    refetchOnWindowFocus: false,
  });

  const showChannelsPage = useFeatureFlag("showChannelsPage");

  const routes = useMemo(
    () =>
      ROUTES.filter((route) => showChannelsPage || route !== "channels").map(
        (route) => ({
          path: menuItems[route].path,
          label: t(menuItems[route].labelTranslationKey),
        }),
      ),
    [showChannelsPage, t],
  );

  useEffect(
    function initialDataExchange() {
      if (loginMutation.isLoading) {
        return;
      }
      if (routeSearch.token) {
        useTokenStore.getState().clear();

        loginMutation.mutate(
          { token: routeSearch.token },
          {
            onSettled: () => {
              // Remove token search from URL after login
              const { token, ...rest } = queryString.parse(
                window.location.search,
              );
              navigate({
                to: window.location.pathname,
                search: rest,
                replace: true,
              });
            },
          },
        );
      }
    },
    [loginMutation, navigate, routeSearch.token],
  );

  useEffect(
    function setGaUserProperties() {
      if (meInfoQuery.data?.organization.uuid) {
        ReactGA4.set({
          user_properties: {
            organization_uuid: meInfoQuery.data.organization.uuid,
          },
        });
      }
    },
    [meInfoQuery.data?.organization.uuid],
  );

  if (
    loginMutation.isError ||
    (!routeSearch.token && !useTokenStore.getState().value) ||
    meInfoQuery.isError ||
    meInfoQuery.data?.menu.length === 0
  ) {
    return (
      <ErrorFallbackPage
        content={t("errorPage.tryFromTheOriginalApp")}
        resetErrorBoundary={() => {}}
        error={undefined}
      />
    );
  }

  if (loginMutation.isLoading || meInfoQuery.isLoading) {
    return <BarLoading wrapperStyle={{ margin: "auto" }} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        minWidth: "960px",
      }}
    >
      <Box
        sx={{
          padding: "24px 8px 32px",
          display: "flex",
          justifyContent: "space-between",
          flex: "0 0 48px",
          flexDirection: "column",
          backgroundColor: theme.colors.colorNeutral100,
        }}
      >
        <Avatar
          alt="Crescendo Lab Logo"
          src={CL_LOGO}
          sx={{ width: 32, height: 32, cursor: "pointer" }}
          onClick={handleDrawerOpen}
        />
        {meInfoQuery.data?.organization.name ? (
          <Tooltip
            title={meInfoQuery.data.organization.name}
            placement="left"
            arrow={true}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "1.25rem",
                fontWeight: 500,
                borderRadius: theme.shape.borderRadius,
                cursor: "pointer",
                bgcolor: stringToColor(meInfoQuery.data.organization.name, [
                  theme.colors.yellow050,
                  theme.colors.green050,
                  theme.colors.purple050,
                  theme.colors.red050,
                ]),
              }}
            >
              {meInfoQuery.data.organization.name.substring(0, 1)}
            </Avatar>
          </Tooltip>
        ) : null}
      </Box>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          "& .MuiDrawer-paper": {
            position: "static",
            width: drawerOpen ? DRAWER_WIDTH : 0,
            border: "none",
            borderRadius: "0px",
            backgroundColor: theme.colors.neutral010,
          },
        }}
        variant="permanent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>{t("sideMenu.adminCenter")}</DrawerHeader>
        <List
          sx={{
            padding: muiTheme.spacing(2, 2),
            display: "flex",
            flexDirection: "column",
            gap: muiTheme.spacing(1),
          }}
        >
          {routes.map((route) => (
            <ListItem key={route.path} disablePadding>
              <ListItemButton
                component="button"
                selected={matches.some((match) =>
                  match.pathname.startsWith(route.path),
                )}
                sx={{
                  padding: "4px 12px",
                  height: "32px",
                  color: theme.colors.neutral090,
                  borderRadius: "4px",
                  "&.Mui-selected": {
                    color: theme.colors.primary,
                    backgroundColor: theme.colors.blue020,
                  },
                  "&.Mui-disabled": {
                    color: theme.colors.neutral060,
                  },
                }}
                onClick={() =>
                  navigate({
                    to: route.path,
                  })
                }
              >
                <ListItemText primary={route.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={drawerOpen}>
        <Box
          sx={{
            width: "100%",
            padding: muiTheme.spacing(3, 5),
            borderRadius: drawerOpen ? "20px 0px 0px 20px" : "none",
            background: theme.colors.neutral000,
            boxShadow: "-2px 0px 4px 0px rgba(34, 59, 83, 0.05)",
          }}
        >
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
});
