import { z } from "zod";

export const GlobalErrorSchema = z.object({
  message: z.string(),
  code: z.enum(["UNAUTHORIZED"]),
});

export const NonGlobalErrorSchema = z.object({
  message: z.string(),
  code: z.enum([
    "BAD_REQUEST",
    "ORGANIZATION_NOT_FOUND",
    "SUBSCRIPTION_NOT_FOUND",
  ]),
});

export const meMenuSchema = z
  .enum(["applications", "billing", "channels"])
  .array();

export type MeMenu = z.output<typeof meMenuSchema>;

export const UnificationKeysSchema = z.enum([
  "display_email",
  "custom_id",
  "connect_id",
  "display_mobile",
  "line_id",
]);

export const OrgSchema = z.object({
  id: z.number().int(),
  source: z.enum(["maac", "caac"]),
  name: z.string(),
  uuid: z.string(),
});

export const UnifyScopeSettingSchema = z.object({
  unifyScopeId: z.number().int(),
  isCdhConnected: z.boolean(),
  orgs: OrgSchema.array(),
  unifyKeys: z
    .object({
      attribute: UnificationKeysSchema,
      isDistinct: z.boolean(),
    })
    .array(),
});

export const ChannelSchema = z.object({
  id: z.number().int().positive(),
  organizationId: z.number().int().positive(),
  uuid: z.string(),
  name: z.string(),
  type: z.enum(["wccs", "whatsapp", "line"]),
  status: z.enum(["connected", "disconnected"]),
  externalChannelId: z.string(),
  channelInformation: z
    .object({
      webChatModuleEnabled: z.boolean(),
      webTrackingEnabled: z.boolean(),
      domains: z
        .object({
          id: z.number().int().positive(),
          url: z.string(),
        })
        .array(),
    })
    .optional(),
});
