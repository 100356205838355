import { makeApi } from "@zodios/core";
import { z } from "zod";

import { ChannelSchema } from "../models";

const api = makeApi([
  {
    alias: "channels",
    method: "get",
    path: "/api/v1/channels",
    response: z.object({
      channels: ChannelSchema.array(),
    }),
  },
]);

export { api };
