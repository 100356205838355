import { fakeT } from "@polifonia/utils/react/fakeT";
import { createRootRoute, createRoute } from "@tanstack/react-router";
import { z } from "zod";

import type { InterludeTypes } from "@/interlude";
import { ErrorFallbackPage } from "@/layout/ErrorBoundary/ErrorFallbackPage/index";
import { tokenSearchSchema } from "@/layout/types";
import { ApplicationPage } from "@/pages/applications";
import { CdhPage } from "@/pages/applications/pages/cdh";
import { BillingPage } from "@/pages/billing";
import { ChannelsPage } from "@/pages/channels";
import { RootComponent } from "@/pages/RootComponent";

const t = fakeT;

export const menuItems = {
  applications: {
    path: "/applications",
    labelTranslationKey: t("sideMenu.applications"),
  } as const,
  billing: {
    path: "/billing",
    labelTranslationKey: t("sideMenu.billing"),
  } as const,
  channels: {
    path: "/channels",
    labelTranslationKey: t("sideMenu.channels"),
  } as const,
} satisfies Record<
  InterludeTypes["meMenu"][number],
  {
    path: string;
    labelTranslationKey: string;
  }
>;

const rootRoute = createRootRoute({
  validateSearch: tokenSearchSchema,
  component: () => <RootComponent />,
  notFoundComponent: () => <RootComponent />,
  errorComponent: (props) => (
    <ErrorFallbackPage
      resetErrorBoundary={() => {}}
      error={props.error}
      reloadWindow
    />
  ),
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <ApplicationPage />,
});

const applicationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/applications",
  component: () => <ApplicationPage />,
});

const cdhRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/applications/cdh",
  component: () => <CdhPage />,
});

const billingRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/billing",
  component: () => <BillingPage />,
});

const channelRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/channels",
  component: () => <ChannelsPage />,
});

const channelDetailRoute = createRoute({
  getParentRoute: () => channelRoute,
  path: "/$type/$id",
  parseParams(rawParams) {
    const { id, type } = rawParams;
    return {
      id: z.number().parse(id),
      type: z.enum(["wccs", "whatsapp", "line"]).parse(type),
    };
  },
});

export const routeTree = rootRoute.addChildren([
  applicationRoute,
  billingRoute,
  cdhRoute,
  channelRoute.addChildren([channelDetailRoute]),
  indexRoute,
]);
