import { api as auth } from "@polifonia/interlude/api/auth";
import { api as balance } from "@polifonia/interlude/api/balance";
import { api as channel } from "@polifonia/interlude/api/channel";
import { api as unifyScopeSetting } from "@polifonia/interlude/api/unifyScopeSetting";
import { generateApi } from "@polifonia/zodios/generateApi";
import type { InferFromModule } from "@polifonia/zodios/InferFromModule";
import { react } from "@polifonia/zodios/react";

import { interludeAxios } from "@/interlude/interludeAxios";

export type InterludeTypes = InferFromModule<
  typeof import("@polifonia/interlude/models")
>;

const api = generateApi({
  api: {
    auth,
    balance,
    channel,
    unifyScopeSetting,
  },
  zodiosOptions: {
    axiosInstance: interludeAxios,
  },
});

const hooks = react(api)();

export { hooks as interlude, api as interludeClient };
