import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { theme } from "@polifonia/theme";
import { useNavigate } from "@tanstack/react-router";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";
import type { InterludeTypes } from "@/interlude";
import { interlude } from "@/interlude";
import { ChannelIcon } from "@/pages/channels/components/ChannelIcon";
import { ChannelStatus } from "@/pages/channels/components/ChannelStatus";
import { WccsActivation } from "@/pages/channels/components/WccsActivation";
import { PageTitle } from "@/pages/components/PageTitle";

export const ChannelsPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const channelsQuery = interlude.channel.useChannels();

  const channels = channelsQuery.data?.channels ?? [];

  const handleNavigateChannelDetail = ({
    type,
    id,
  }: {
    type: InterludeTypes["Channel"]["type"];
    id: number;
  }) => {
    navigate({
      to: `/channels/$type/$id`,
      params: { id, type },
    });
  };

  return (
    <>
      <PageTitle>{t("channels.title")}</PageTitle>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        css={css({ marginBottom: 24 })}
      >
        <Button>{t("channels.button.create")}</Button>
      </Grid>
      <TableContainer>
        <Table aria-label="channel table">
          <TableHead>
            <TableRow>
              <TableCell width={400}>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("channels.table.column.name")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("channels.table.column.status")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("channels.table.column.activation")}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {channels.map((channel) => (
              <TableRow key={channel.id}>
                <TableCell component="th" scope="row">
                  <Stack
                    direction="row"
                    spacing="10px"
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <ChannelIcon type={channel.type} />
                    <Stack>
                      <Typography
                        variant="body"
                        fontWeight={400}
                        color={theme.colors.staticFgTitle}
                      >
                        {channel.name}
                      </Typography>
                      {channel?.channelInformation?.domains.map((domain) => (
                        <Typography
                          key={domain.id}
                          variant="note"
                          fontWeight={500}
                          color={theme.colors.staticFgNote}
                        >
                          {domain.url}
                        </Typography>
                      ))}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell>
                  <ChannelStatus status={channel.status} />
                </TableCell>
                <TableCell>
                  {channel.type === "wccs" ? (
                    <WccsActivation
                      webTrackingEnabled={
                        channel.channelInformation?.webTrackingEnabled
                      }
                      webChatModuleEnabled={
                        channel.channelInformation?.webChatModuleEnabled
                      }
                    />
                  ) : null}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="secondary"
                    css={css({
                      padding: 6,
                      minWidth: "auto",
                      border: `1px solid ${theme.colors.staticFgLine}`,
                    })}
                    onClick={() => {
                      handleNavigateChannelDetail({
                        type: channel.type,
                        id: channel.id,
                      });
                    }}
                  >
                    <MotifIcon
                      un-i-motif="edit"
                      css={css({
                        fontSize: 16,
                        color: theme.colors.buttonPlainDefault,
                      })}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
