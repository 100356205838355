import { css } from "@emotion/react";
import { Box } from "@mui/material";
import { theme } from "@polifonia/theme";
import type { FC } from "react";

import { MotifIcon } from "@/components/MotifIcon";
import type { InterludeTypes } from "@/interlude";

interface ChannelIconProps {
  type: InterludeTypes["Channel"]["type"];
}

const channelIconMap: Record<ChannelIconProps["type"], React.ReactNode> = {
  wccs: (
    <MotifIcon
      un-i-motif="earth"
      css={css({
        color: theme.colors.staticFgPrimary,
      })}
    />
  ),
  whatsapp: <MotifIcon un-i-motif="whatsapp" />,
  line: <MotifIcon un-i-motif="line_color" />,
};

export const ChannelIcon: FC<ChannelIconProps> = ({ type }) => {
  return (
    <Box
      sx={{
        fontSize: 24,
      }}
    >
      {channelIconMap[type]}
    </Box>
  );
};
