import { availableLocales } from "@/config/availableLocales";
import { defineConfig } from "@/features/featureFlag/defineConfig";

const languageOptions = [
  ...availableLocales.map((locale) => ({
    value: locale.code,
    label: locale.displayName,
  })),
  /**
   * HINT: For easy testing - setting lng to 'cimode' will set t function to always return the key.
   */
  {
    label: "Keys",
    value: "cimode",
  },
];

export const featureFlagConfigs = defineConfig({
  alwaysHideDevModeHint: {
    type: "toggle",
    label: "Always hide DevMode hint",
  },
  showChannelsPage: {
    type: "toggle",
    label: "Show Channels page entry on Sidemenu",
  },
  quickI18n: {
    label: "Quick switch language",
    type: "singleSelect",
    options: languageOptions,
  },
  "tanstack-router-devtool": {
    label: "Enable tanstack router devtool",
    type: "singleSelect",
    options: [
      {
        value: "bottom-right",
        label: "Bottom right",
      },
      {
        value: "top-left",
        label: "Top left",
      },
      {
        value: "top-right",
        label: "Top right",
      },
      {
        value: "bottom-left",
        label: "Bottom left",
      },
    ],
  },
  "react-query-devtool": {
    label: "Enable react-query devtool",
    type: "singleSelect",
    options: [
      {
        value: "bottom",
        label: "Bottom",
      },
      {
        value: "top",
        label: "Top",
      },
      {
        value: "right",
        label: "Right",
      },
      {
        value: "left",
        label: "Left",
      },
    ],
  },
});
