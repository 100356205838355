import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components/Badge";
import type { InterludeTypes } from "@/interlude";

interface ChannelStatusProps {
  status: InterludeTypes["Channel"]["status"];
}

export const ChannelStatus: FC<ChannelStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case "connected":
      return <Badge variant="positive">{t("channels.status.connect")}</Badge>;
    case "disconnected":
      return <Badge variant="plain">{t("channels.status.disconnected")}</Badge>;
    default:
      status satisfies never;
      return null;
  }
};
